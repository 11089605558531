import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import BannerImg from "../../../../Assets/images/home/about-manuel.svg"
import LogoImg from "../../../../Assets/images/home/logo.png"
import {Link} from "react-router-dom"
export default function AboutManuel() {
    return (
        <section>
            <Container fluid className="home-showcase-container">
                <Row className="about_manuel_section">
                    <Col lg={6} className="left_section order-2 order-lg-1">
                        <div className="box">
                            <h2>About Manuel Spinosa </h2>
                            <p> Manuel Spinosa Jewelry is a family business founded in 1958. </p>
                            <p> We are experts in creating fine diamond Jewelry such as:engagement rings, diamond wedding rings, diamond
                                solitaires, Riviere necklace and bracelets,
                                bridal earrings, etc. </p>
                            <p> We are located in Marbella and we are the only Center
                                specialized in diamonds on the entire Costa Sol and
                                the province of Malaga </p>
                            <Link to="/our-story">  <button className="golden-btn">Read More</button>
                            </Link>
                        </div>
                    </Col>
                    <Col lg={6} className="right_section order-1 order-lg-2">
                        <div className="box">
                            <h2> Why ? </h2>
                            <div className="img-box text-center">
                                <img src={LogoImg} alt="Logo" className="img-fluid logo" />
                                <img src={BannerImg} alt="About Manuel" className="img-fluid" />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
