import React from 'react'
import Img from "../Assets/img/together-1.webp";
import Img2 from "../Assets/img/couple.jpg";
import { Container } from 'react-bootstrap'
import Heading from "../ui/Heading";
import {Link} from "react-router-dom"

export default function Together() {
    return (
        <section>
            <Container fluid className="together_section">
                <div className="right_section">
                    <img src={Img2} alt={"main Image"} className="img-fluid d-none d-lg-block" />
                    <img src={Img} alt={"main Image"} className="img-fluid d-block d-lg-none" />
                </div>

                <div className="left_section">
                    <div>
                        <Heading>
                            <h2> The Beauty of Togetherness
                                with Fine Jewelry. </h2>
                            <p>
                                Celebrate your unique journey with our luxurious
                                Jewelry collection, designed to reflect the depth
                                of your love. Perfect for couples who appreciate
                                the finer things in life.
                            </p>
                        </Heading>
                        <Link to="/jewelry/fine-jewelry">
                            <button className="golden-btn"> Shop Now </button>
                        </Link>
                    </div>
                </div>
            </Container>
        </section>
    )
}
