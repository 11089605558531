import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Img from "../Assets/img/consult-specilest.webp";

import { Je_welryspelling } from "../../../../Helpers/request";
import Heading from "../ui/Heading";

const ConsultSpecialist = () => {
  return (
    <section className="consult-specialist-section section-space pt-0">
      <Container fluid className="home-showcase-container">
        <div>
          <Row>
            <Col md={6} className="">
              <div className="inner-wrap text-center text-lg-left">
                <Heading className="text-center text-lg-left">
                  <h2 className="mb-0">Consult With A {Je_welryspelling}  Specialist</h2>
                </Heading>

                <p>
                  Connect with our expert jewelry specialists for personalized guidance
                  and advice on selecting the perfect piece. Whether you're looking for an
                  engagement ring, a timeless necklace, or a custom design, our specialists
                  are here to help you find exactly what you need.
                </p>
                <p>
                  Enjoy a one-on-one consultation where we’ll discuss your preferences,
                  budget, and style to ensure you make an informed decision.
                </p>
                <div className="btn-group">
                  <Link
                    to="/make-an-appointment"
                  >
                    <button className="golden-btn"> Book A Virtual Appointment </button>

                  </Link>
                  <Link
                    to="/make-an-appointment"
                  >
                    <button className="golden-btn"> Book A Visit To Our Showroom </button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={6} className="p-0 pr-lg-0 my-auto">
              <Image src={Img} alt="Feel Free To Talk With Us" className="img-fluid" />
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default ConsultSpecialist;
