import { RiFacebookBoxFill, RiTwitterXFill, RiInstagramLine, RiYoutubeFill, RiLinkedinBoxFill } from "react-icons/ri";
import { Navigate, Outlet } from "react-router";
import Social1 from '../Assets/images/static/Social/facebook.png';
import Social2 from '../Assets/images/static/Social/instagram.png';
import Social3 from '../Assets/images/static/Social/youtube.png';
import Social7 from '../Assets/images/static/Social/7.png';
import { useSelector } from "react-redux";
//-------------------------------------------------url
export const base_url = "https://manuelspinosa.com/webapi/api";
export const img_url = "https://manuelspinosa.com/";
export const helmet_url = "https://manuelspinosa.com/";
export const is_paypal = true;
export const is_square = true;
export const isShowPrice = false;
export const Je_welryspelling = "Jewelry";

// ------Contact Details Start ------
export const contact_number = {                // <===---contact Number //
  text: '+34 952 82 30 77',
  link: `tel:+34952823077`,
  chat: '+34649891997'
};

export const Email_details = {                      // <===---Email_Details  //
  text: 'info@manuelspinosa.com',
  link: `mailto:info@manuelspinosa.com`
};

export const Email_detailsSecond = {                // <===---Second_Email_details  // 
  text: 'sales@belgiumwebnet.com',
  link: `mailto:sales@belgiumwebnet.net`
};
export const Address_details = {                     // <===---Address_details  // 
  textLine1: 'Avenida Miguel Cano,',
  textLine2: '7 29602 Marbella (Málaga) Spain',
  link: "https://www.google.com/maps/place/Manuel+Spinosa+Jewellery/@36.5081545,-4.8868923,824m/data=!3m2!1e3!4b1!4m6!3m5!1s0xd7327f8eb2ad063:0x8289ca7266c413bc!8m2!3d36.5081545!4d-4.8868923!16s%2Fg%2F1vnnkrwk?entry=ttu&g_ep=EgoyMDI0MTAxMy4wIKXMDSoASAFQAw%3D%3D"
};

export const client_name = {                          // <===---client_name  // 
  c_name: 'Manuel Spinosa',
};

export const media_details = [                           // <===---media_details  // 
  {
    account_alt: 'Facbook',
    account_icon: <RiFacebookBoxFill />,
    account_img: Social1,
    account_link: "https://www.facebook.com/www.manuelspinosa.es"
  },
  {
    account_alt: 'Instgram',
    account_icon: <RiInstagramLine />,
    account_img: Social2,
    account_link: "https://www.instagram.com/manuelspinosajewellery?igsh=MWptYmhjN3ZlMm1tMQ=="
  },
  {
    account_alt: 'Youtube',
    account_icon: <RiYoutubeFill />,
    account_img: Social3,
    account_link: "https://youtube.com/@manuelspinosa?si=IRU7txqVB5GfKqPZ"
  },
  // {
  //   account_alt: 'Twitter',
  //   account_icon: <RiTwitterXFill />,
  //   account_img: Social7,
  //   account_link: "https://twitter.com/belgiumwebnet"
  // },

  // {
  //   account_alt: 'Linkedin',
  //   account_icon: <RiLinkedinBoxFill />,
  //   account_img: Social3,
  //   account_link: "https://www.linkedin.com/company/belgium-webnet/",
  // },
];


export const InvoiceAddress = () => {              // <===---Invoice_Address  //
  return (
    <>
      <h4 className="mb-2">  Av. Miguel Cano, 7, 29602  </h4>
      <h4 className="mb-0"> Marbella,  Málaga, Spain </h4>
    </>
  )
};

//--------------------------------------------------Api header data
export const postHeader = {
  // 'authorization':`Token e222085edc871220cae528152b73c1317ba2addf`,
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

export const onTop = () => {
  window.scrollTo(0, 0);
}

export const ProtectedRoutes = () => {
  const user = useSelector(
    (state) => state.persistedReducer.home.userData
  );
  return user?.isLogin ? <Outlet /> : <Navigate to="/" />
}
//---------------------------------------------------Ring builder
export const setSteps = (check) => {
  const diamonddata = JSON.parse(localStorage.getItem('bw-diamonddata')) ? JSON.parse(localStorage.getItem('bw-diamonddata')) : null;
  const settingdata = JSON.parse(localStorage.getItem('bw-settingdata')) ? JSON.parse(localStorage.getItem('bw-settingdata')) : null;
  const gemstonedata = JSON.parse(localStorage.getItem('bw-gemstonedata')) ? JSON.parse(localStorage.getItem('bw-gemstonedata')) : null;
  const fancycolordata = JSON.parse(localStorage.getItem('bw-fancycolordata')) ? JSON.parse(localStorage.getItem('bw-fancycolordata')) : null;
  var arr;
  if (check == 0) {
    if (settingdata == null) {
      arr = [0, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (settingdata != null) {
      arr = [3, 0];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 1) {
    if (settingdata == null) {
      arr = [1, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (settingdata != null) {
      arr = [3, 1];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 2) {
    if (settingdata == null) {
      arr = [2, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (settingdata != null) {
      arr = [3, 2];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 3) {
    if (diamonddata == null) {
      arr = [3, 1];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (diamonddata != null) {
      arr = [1, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 4) {
    if (gemstonedata == null) {
      arr = [3, 2];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (gemstonedata != null) {
      arr = [2, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 5) {
    if (fancycolordata == null) {
      arr = [3, 0];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (fancycolordata != null) {
      arr = [0, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  }
}

//---------------------------------------------------stud builder
export const setStudSteps = (check) => {
  const diamonddata = JSON.parse(localStorage.getItem('bw-stud-diamonddata')) ? JSON.parse(localStorage.getItem('bw-stud-diamonddata')) : null;
  const settingdata = JSON.parse(localStorage.getItem('bw-stud-settingdata')) ? JSON.parse(localStorage.getItem('bw-stud-settingdata')) : null;
  const gemstonedata = JSON.parse(localStorage.getItem('bw-stud-gemstonedata')) ? JSON.parse(localStorage.getItem('bw-stud-gemstonedata')) : null;
  const fancycolordata = JSON.parse(localStorage.getItem('bw-stud-fancycolordata')) ? JSON.parse(localStorage.getItem('bw-stud-fancycolordata')) : null;
  var arr;
  if (check == 0) {
    if (settingdata == null) {
      arr = [0, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    } else if (settingdata != null) {
      arr = [3, 0];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    }
  } else if (check == 1) {
    if (settingdata == null) {
      arr = [1, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    } else if (settingdata != null) {
      arr = [3, 1];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    }
  } else if (check == 2) {
    if (settingdata == null) {
      arr = [2, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    } else if (settingdata != null) {
      arr = [3, 2];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    }
  } else if (check == 3) {
    if (diamonddata == null) {
      arr = [3, 1];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    } else if (diamonddata != null) {
      arr = [1, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    }
  } else if (check == 4) {
    if (gemstonedata == null) {
      arr = [3, 2];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    } else if (gemstonedata != null) {
      arr = [2, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    }
  } else if (check == 5) {
    if (fancycolordata == null) {
      arr = [3, 0];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    } else if (fancycolordata != null) {
      arr = [0, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    }
  }
}


//------------------- Image error------------------------

export const ErrorImg = (dia_shape, id, type) => {
  if (dia_shape == "Round") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/round.jpg";
  } else if (dia_shape == "Princess") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/princess.jpg";
  } else if (dia_shape == "Cushion") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/cushion.jpg";
  } else if (dia_shape == "Emerald") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/emerald.jpg";
  } else if (dia_shape == "Oval") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/oval.jpg";
  } else if (dia_shape == "Radiant") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/radiant.jpg";
  } else if (dia_shape == "Asscher") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/asscher.jpg";
  } else if (dia_shape == "Marquise") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/marquise.jpg";
  } else if (dia_shape == "Heart") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/heart.jpg";
  } else if (dia_shape == "Pear") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/pear.jpg";
  } else if (dia_shape == "Gemstone") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/gemstone.png";
  } else if (dia_shape == "Fancy") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/fancy_diamond.png";
  } else if (dia_shape === "none") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/image-not-found.png";
  } else {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/diamond.png";
  }


  if (type == "detail" || type == "Complete") {
    if (type == "Complete") {
      var imgsrc = document.getElementsByClassName("image-gallery-thumbnail-image")[1].getAttribute("src");
      var image = new Image();
      image.src = imgsrc;
      image.onerror = () => {
        document.getElementsByClassName("image-gallery-thumbnail-image")[1].src = img;
      }
    } else {
      var imgsrc = document.getElementsByClassName("image-gallery-thumbnail")[0].getElementsByTagName("img")[0].getAttribute("src");
      var image = new Image();
      image.src = imgsrc;
      image.onerror = () => {
        document.getElementsByClassName("image-gallery-thumbnail-image")[type == "Complete" ? 1 : 0].src = img;
        document.getElementsByClassName("image-to-magnify")[0].getElementsByTagName("img")[0].src = img;
      }
      image.onload = () => {
        document.getElementsByClassName("image-to-magnify")[0].getElementsByTagName("img")[0].src = imgsrc;
      }
    }
    // image.onload = () => {
    //   document.getElementsByClassName("image-to-magnify")[0].getElementsByTagName("img")[0].src = imgsrc;
    // }
  } else {
    document.getElementById(id).src = img;
  }
}