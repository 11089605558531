import React from "react";
import Heading from "../ui/Heading";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Slider from "react-slick";
import LeftIcon from "../../../../Assets/images/icon/left.svg"
import RightIcon from "../../../../Assets/images/icon/right.svg"
import RingImg from "../../../../Assets/images/home/ring-category.webp"
import BraceletImg from "../../../../Assets/images/home/bracelete.svg"
import NacklessImg from "../../../../Assets/images/home/neckless.svg"
import EarringImg from "../../../../Assets/images/home/Earrings.jpg"
import PendantImg from "../../../../Assets/images/home/pendant-sin.jpg"
const ExploreCollection = () => {

  const LeftArrow = (props) => {
    return (
      <div className="slick-arrow slick-prev" {...props}>
        <img src={LeftIcon} alt="Left Arrow" />
      </div>
    );
  };

  const RightArrow = (props) => {
    return (
      <div className="slick-arrow slick-next" {...props}>
        <img src={RightIcon} alt="Right Arrow" />
      </div>
    );
  };


  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <LeftArrow />,
    nextArrow: <RightArrow />,
    responsive: [

      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="explore-collection section-space">
      <Container fluid className="home-showcase-container">
        <Heading>
          <h2> Shop by Category </h2>
          <p>  Exceptional design and unmatched craftsmanship.</p>
        </Heading>

        <div className="inner-shop-diamond-shape">
          <div className="diamonds-collection">
            <Slider {...settings}>
              {/* {data.map((item, i) => (
                  <div
                    className="diamond-item cursor-pointer"
                    key={i}
                    onClick={() => setDiamondItem(item)}
                  >
                    <Image src={item.image} alt="Round" />
                    <p className={`${item.name === item.name && "name"}`}>
                      {item.name}
                    </p>
                     
                  </div>
                ))} */}

              {/* <div className="item text-center">
                <div className="box-overlay"></div>
                <Link to="/jewelry/fashion-rings" className="img-box">
                  <Image src={RingImg} alt="Ring" />
                </Link>
                <Link to="/jewelry/fashion-rings" className="link">Rings</Link>
              </div> */}

              <div className="item text-center">
                <div className="box-overlay"></div>
                <Link to="/jewelry/bracelets" className="img-box">
                  <Image src={BraceletImg} alt="Bracelet" />
                </Link>
                <Link to="/jewelry/bracelets" className="link">Bracelets</Link>
              </div>
              {/* <div className="item text-center">
                <div className="box-overlay"></div>
                <Link to="/jewelry/pendants" className="img-box"> 
                  <Image src={PendantImg} alt="Bracelet" />
                </Link>
                <Link to="/jewelry/pendants" className="link">Pendants</Link>
              </div> */}
              <div className="item text-center">
                <div className="box-overlay"></div>
                <Link to="/jewelry/earrings" className="img-box">
                  <Image src={EarringImg} alt="Earring" />
                </Link>
                <Link to="/jewelry/earrings" className="link">Earrings</Link>
              </div>

              <div className="item text-center">
                <div className="box-overlay"></div>
                <Link to="/jewelry/pendants" className="img-box">
                  <Image src={PendantImg} alt="Necklace" />
                </Link>
                <Link to="/jewelry/pendants" className="link">Pendants</Link>
              </div>
            </Slider>
          </div>
        </div>

        {/* <Row>

          <Col md={3} xs={6}>
            <div className="item text-center">
              <Link to="/jewelry/fashion-rings" className="img-box">
                <Image src={Ring} alt="Ring" />
              </Link>
              <Link to="/jewelry/fashion-rings">Rings</Link>
            </div>
          </Col>
          <Col md={3} xs={6}>
            <div className="item text-center">
              <Link to="/jewelry/bracelets" className="img-box">
                <Image src={Bracelet} alt="Bracelet" />
              </Link>
              <Link to="/jewelry/bracelets">Bracelets</Link>
            </div>
          </Col>
          <Col md={3} xs={6}>
            <div className="item text-center">
              <Link to="/jewelry/earrings" className="img-box">
                <Image src={Earring} alt="Earring" />
              </Link>
              <Link to="/jewelry/earrings">Earrings</Link>
            </div>
          </Col>
          <Col md={3} xs={6}>
            <div className="item text-center">
              <Link to="/jewelry/necklaces" className="img-box">
                <Image src={Necklace} alt="Necklace" />
              </Link>
              <Link to="/jewelry/necklaces">Necklaces</Link>
            </div>
          </Col>
        </Row> */}
      </Container>
    </section>
  );
};

export default ExploreCollection;
