import React, { useState } from "react";
import Heading from "../ui/Heading";
import { Col, Container, Image, Row } from "react-bootstrap";
import Round from "../Assets/img/diamond-shape/round-diamond.webp";
import Princess from "../Assets/img/diamond-shape/princess-diamond.webp";
import Cushion from "../Assets/img/diamond-shape/cushion-diamond.webp";
import Emerald from "../Assets/img/diamond-shape/emerald-diamond.webp";
import Oval from "../Assets/img/diamond-shape/oval-diamond.webp";
import Radiant from "../Assets/img/diamond-shape/radiant-diamond.webp";
import Asscher from "../Assets/img/diamond-shape/asscher-diamond.webp";
import Marquise from "../Assets/img/diamond-shape/marquise-diamond.webp";
import Heart from "../Assets/img/diamond-shape/heart-diamond.webp";
import Pear from "../Assets/img/diamond-shape/pear-diamond.webp";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import Slider from "react-slick";    
import { Je_welryspelling } from "../../../../Helpers/request";
import LeftIcon from "../../../../Assets/images/icon/left.svg"
import RightIcon from "../../../../Assets/images/icon/right.svg"
const data = [
  {
    name: "Round",
    para: "Cut with the optimum light refraction and sparkle.",
    image: Round,
    url: "/ringsettings/shape=Round",
  },
  // {
  //   name: "Princess",
  //   para: "A contemporary cut, with optimal fire and brilliance.",
  //   image: Princess,
  //   url: "/ringsettings/shape=Princess",
  // },
  {
    name: "Cushion",
    para:
      "With soft corners, a beautiful alternative to a round or princess cut.",
    image: Cushion,
    url: "/ringsettings/shape=Cushion",
  },
  {
    name: "Emerald",
    para:  "This stylish cut creates rectangular facets with great stone's clarity.",
    image: Emerald,
    url: "/ringsettings/shape=Emerald",
  },
  {
    name: "Oval",
    para: "Optimises carat, giving the illusion of a larger stone.",
    image: Oval,
    url: "/ringsettings/shape=Oval",
  },
  // {
  //   name: "Radiant",
  //   para: "The most brilliant of the rectangular cut diamonds.",
  //   image: Radiant,
  //   url: "/ringsettings/shape=Radiant",
  // },
  // {
  //   name: "Asscher",
  //   para: "This unique shape is a square emerald, with linear facets.",
  //   image: Asscher,
  //   url: "/ringsettings/shape=Asscher",
  // },
  // {
  //   name: "Marquise",
  //   para: "Elongate the finger of the wearer with a look of great grandeur.",
  //   image: Marquise,
  //   url: "/ringsettings/shape=Marquise",
  // },
  {
    name: "Heart",
    para: "A rare cut that is a true testament of your relationship.",
    image: Heart,
    url: "/ringsettings/shape=Heart",
  },
  {
    name: "Pear",
    para: "Combining the brilliance and design of the Round and Marquise cuts.",
    image: Pear,
    url: "/ringsettings/shape=Pear",
  },
];

const LeftArrow = (props) => {
  return (
    <div className="slick-arrow slick-prev" {...props}>
      <img src={LeftIcon} alt="Left Arrow" />
    </div>
  );
};

const RightArrow = (props) => {
  return (
    <div className="slick-arrow slick-next" {...props}>
      <img src={RightIcon} alt="Right Arrow" />
    </div>
  );
};

const ShopByDiamondShape = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    prevArrow: <LeftArrow />,
    nextArrow: <RightArrow />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [diamondItem, setDiamondItem] = useState(data[0]);

  return (
    <>
      <section className="shop-diamond-by-shape section-space">
        <Container fluid className="home-showcase-container">
          <Heading>
            <h2> Shop Rings By Diamond Shape </h2>
            <p>  Looking To Buy a Diamond Engagement Ring? Choose Your Preferred Shape And Explore A Wide at Our Shop.</p>
          </Heading>
          <div className="inner-shop-diamond-shape">
            <div className="diamonds-collection">
              <Slider {...settings}>
                {data.map((item, i) => (
                   <Link 
                   to={item.url}
                 >
                  <div
                    className="diamond-item cursor-pointer"
                    key={i}
                    onClick={() => setDiamondItem(item)}
                  >
                    <Image src={item.image} alt="Round" />
                    <p className={`${item.name === item.name && "name"}`}>
                      {item.name}
                    </p>
                    {/* <p className={"desc"}>{item.para}</p>
                      <Link
                        className="btn outline-base-btn small"
                        to={item.url}
                      >
                        Shop {item.name} Diamond
                      </Link> */}
                  </div>
                  </Link> 
                ))}
              </Slider>
            </div>
          </div>
          {/* <div className="inner-shop-diamond-shape">
            {isMobile ? (
              <div className="diamonds-collection">
                <Slider {...settings}>
                  {data.map((item, i) => (
                    <div
                      className="diamond-item cursor-pointer"
                      key={i}
                      onClick={() => setDiamondItem(item)}
                    >
                      <Image src={item.image} alt="Round" />
                      <p className={`${item.name === item.name && "name"}`}>
                        {item.name}
                      </p>
                      <p className={"desc"}>{item.para}</p>
                      <Link
                        className="btn outline-base-btn small"
                        to={item.url}
                      >
                        Shop {item.name} Diamond
                      </Link>
                    </div>
                  ))}
                </Slider>
              </div>
            ) : isTablet ? (
              <div className="diamonds-collection">
                <Slider {...settings}>
                  {data.map((item, i) => (
                    <div
                      className="diamond-item cursor-pointer"
                      key={i}
                      onClick={() => setDiamondItem(item)}
                    >
                      <Image src={item.image} alt="Round" />
                      <p className={`${item.name === item.name && "name"}`}>
                        {item.name}
                      </p>
                      <p className={"desc"}>{item.para}</p>
                      <Link
                        className="btn outline-base-btn small"
                        to={item.url}
                      >
                        Shop {item.name} Diamond
                      </Link>
                    </div>
                  ))}
                </Slider>
              </div>
            ) : (
              <Row className="align-items-center">
                <Col md={7}>
                  <div className="diamonds-collection">
                    {data.map((item, i) => (
                      <div
                        className="diamond-item cursor-pointer"
                        key={i}
                        onClick={() => setDiamondItem(item)}
                      >
                        <Image src={item.image} alt="Round" />
                        <p
                          className={`${diamondItem.name === item.name && "font-weight-bold"
                            }`}
                        >
                          {item.name}
                        </p>
                      </div>
                    ))}
                  </div>
                </Col>

                <Col md={5}>
                  <div className="diamonds-item-brief d-flex align-items-center">
                    <Image src={diamondItem.image} alt="" />

                    <div className="content">
                      <h2>{diamondItem.name}</h2>
                      <p>{diamondItem.para}</p>
                      <Link
                        className="btn outline-base-btn small"
                        to={diamondItem.url}
                      >
                        Shop {diamondItem.name} Diamond
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </div> */}
        </Container>
      </section>
      {/* {isMobile ? <img className='p-2 img-fluid' src={divider} alt='' /> : ''} */}
    </>
  );
};

export default ShopByDiamondShape;
